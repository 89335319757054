<template>
  <section class="home">
    <div class="home__herobanner">
      <router-link to="/catalogue">
        <img
          src='@/assets/img/header.webp'
          alt="Hero banner"
        />
        <h2 class="home__herobanner-text">
          Découvrez nos produits
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 256 256"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M205.41,151.07a60.9,60.9,0,0,1-31.83,8.86,71.71,71.71,0,0,1-27.36-5.66A55.55,55.55,0,0,0,136,186.51V216a8,8,0,0,1-8.53,8,8.18,8.18,0,0,1-7.47-8.25V203.31L81.38,164.69A52.5,52.5,0,0,1,63.44,168a45.82,45.82,0,0,1-23.92-6.67C17.73,148.09,6,117.62,8.27,79.79a8,8,0,0,1,7.52-7.52c37.83-2.23,68.3,9.46,81.5,31.25A46,46,0,0,1,103.74,132a4,4,0,0,1-6.89,2.43l-19.2-20.1a8,8,0,0,0-11.31,11.31l53.88,55.25c.06-.78.13-1.56.21-2.33a68.56,68.56,0,0,1,18.64-39.46l50.59-53.46a8,8,0,0,0-11.31-11.32l-49,51.82a4,4,0,0,1-6.78-1.74c-4.74-17.48-2.65-34.88,6.4-49.82,17.86-29.48,59.42-45.26,111.18-42.22a8,8,0,0,1,7.52,7.52C250.67,91.65,234.89,133.21,205.41,151.07Z"
            ></path>
          </svg>
        </h2>
      </router-link>
    </div>
    <div class="home__about">
      <h2>Plongez au cœur de l'univers envoûtant du Thé</h2>
      <p>
        Bienvenue dans le monde enchanté du thé, où chaque tasse révèle une
        histoire unique et une expérience sensorielle inoubliable. Chez <span class="font-logo">Tea & Vue</span>, notre vocation est de vous transporter dans un voyage au pays des
        saveurs et des arômes exquis.
      </p>
      <h3>L'art du Thé : une passion, une expertise</h3>
      <p>
        Notre fondateur, passionné par l'art du thé depuis son plus jeune âge, a
        parcouru les terres lointaines où cette précieuse feuille est cultivée
        et récoltée avec amour. De ces périples est née la conviction profonde
        que chaque variété de thé est un trésor unique à partager.
      </p>
      <p>
        Notre équipe dévouée, formée par les meilleurs experts en thé,
        sélectionne méticuleusement les feuilles les plus délicates et les
        mélanges les plus raffinés. Du thé noir au thé vert, du thé oolong au
        thé blanc, nous offrons une palette complète de saveurs, d'arômes et de
        bienfaits pour satisfaire les palais les plus exigeants.
      </p>
    </div>
  </section>
</template>
