<template>
  <section class="about">
    <div class="about__header">
      <h2>À propos</h2>
    </div>
    <div class="about__content">
      <img src="@/assets/img/about.webp" alt="">
      <div>
        <div>
          <h3>Notre Histoire</h3>
          <p><span class="font-logo">Tea & Vue</span> est née de la passion partagée pour l'art du thé. Nous sommes un groupe de passionnés de thé qui ont décidé de créer un endroit où l'amour pour cette boisson ancestrale pourrait être partagé et célébré.</p>
        </div>
        <div>
          <h3>Notre Mission</h3>
          <p>Notre mission est de vous offrir une expérience exceptionnelle, une escapade sensorielle à chaque tasse de thé. Nous nous engageons à vous procurer des thés de la plus haute qualité, provenant des coins les plus exotiques du globe. Chaque feuille de thé que nous sélectionnons est méticuleusement choisie pour vous offrir des arômes et des saveurs uniques, le tout dans une ambiance enchanteresse.</p>
        </div>
        <div>
          <h3>Notre Engagement envers la Qualité</h3>
          <p>Chez <span class="font-logo">Tea & Vue</span>, la qualité est notre priorité. Nous travaillons en étroite collaboration avec des artisans passionnés et des producteurs de thé renommés pour garantir que chaque lot de thé que nous proposons répond à nos normes rigoureuses. Nos thés sont issus de récoltes durables et respectueuses de l'environnement, ce qui contribue à promouvoir une planète plus saine.</p>
        </div>
        <div>
          <h3>Notre Gamme de Thés</h3>
          <p>Explorez notre collection variée de thés soigneusement sélectionnés, allant du thé noir robuste au thé vert rafraîchissant, en passant par les infusions apaisantes. Nous avons quelque chose pour chaque palais et chaque occasion. Découvrez de nouveaux favoris et laissez-vous envoûter par les différentes notes et subtilités que le monde du thé a à offrir.</p>
        </div>
      </div>
    </div>
  </section>
</template>
